<template>
    <div id="log_manage">
        <jy-query ref="form" :model="form">
            <jy-query-item label="字典名称:" prop="cName">
                <el-input placeholder="请输入" v-model="form.cName"></el-input>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('wastesSystemSettingsDictList')">查询</el-button>
                <el-button type="primary" @click="resetForm('form')" v-if="btnexist('wastesSystemSettingsDictReset')">重置</el-button>
                <el-row>
                    <!-- <el-button type="primary">导出</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <jy-table :data="dataList">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column prop="cName" label="字典名称"></jy-table-column>
            <jy-table-column label="描述">
                <template v-slot="scope">
                    {{ scope.row | getDespValues }}
                </template>
            </jy-table-column>
            <jy-table-column prop="uUserId" label="更新人"></jy-table-column>
            <jy-table-column prop="updateT" label="更新时间"></jy-table-column>
            <jy-table-column prop="cUserId" label="创建人"></jy-table-column>
            <jy-table-column prop="createT" label="创建时间"></jy-table-column>
            <jy-operate v-bind:list="operateList" width="200"></jy-operate>
        </jy-table>
        <jy-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageIndex"
            :page-size="pageSize"
            :total="total"
        ></jy-pagination>

        <update ref="update" @update="getList"></update>
    </div>
</template>
<script>
    import { btnMixins } from "@/common/js/button.mixin";
    import Update from "@/components/pages/admin/systemSettings/dictManagement/Update.vue";
    export default {
        filters: {
            getDespValues(row) {
                if (row.dictValueList) {
                    return row.dictValueList.map(v => v.iValue).join(",");
                } else {
                    return "-";
                }
            }
        },
        data() {
            return {
                form: {
                    cName: ""
                },
                pageSize: 10,
                total: 100,
                pageIndex: 1,
                dataList: [],
                operateList: [],

                btnMenuId: "wastesSystemSettingsDict"
            };
        },
        mixins: [btnMixins],
        created() {
            this.getList();
            this.setOperateList();
        },
        methods: {
            setOperateList() {
                let l = [
                    {
                        name: "查看",
                        icon: "el-icon-edit-outline",
                        fun: this.handleView,
                        isShow: () => {
                            return this.btnexist("wastesSystemSettingsDictView");
                        }
                    },
                    {
                        name: "修改",
                        icon: "el-icon-edit-outline",
                        fun: this.handleUpdate,
                        isShow: () => {
                            return this.btnexist("wastesSystemSettingsDictUpdate");
                        }
                    }
                ];
                this.operateList = l;
            },
            handleUpdate(row) {
                this.$refs.update.init(row);
            },
            handleView(row) {
                this.$refs.update.init(row, true);
            },
            getList() {
                let option = {
                    ...this.form,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                };
                let url = "/dict/pageQuery";
                console.log(option);
                this.$http.post(url, option).then(({ detail }) => {
                    console.log(detail);
                    this.dataList = detail.list;
                    this.total = detail.total;
                    console.log(this.dataList);
                });
            },
            // 查询表格
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            }
        },
        components: {
            Update
        }
    };
</script>
