<template>
    <el-dialog :visible.sync="visible" :title="title">
        <el-form ref="form" :model="form" label-position="right">
            <el-form-item label="字典名称" prop="name" label-width="80px">
                <el-input v-model="form.name" disabled></el-input>
            </el-form-item>
            <el-form-item label="字典类型" prop="dictId" label-width="80px">
                <el-input v-model="form.dictId" disabled></el-input>
            </el-form-item>
            <el-form-item>
                <el-table :data="form.dictValueList">
                    <el-table-column label="字典值" prop="iValue" align="center">
                        <template slot-scope="scope" v-if="!isView">
                            <el-input v-model="scope.row.iValue" v-if="scope.row.isAdd"></el-input>
                            <span v-else>{{ scope.row.iValue }}</span>
                        </template>
                        <template slot-scope="scope" v-else>
                            <span>{{ scope.row.iValue }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="hasRV" label="真值" prop="rValue" align="center">
                        <template slot-scope="scope" v-if="!isView">
                            <el-input v-model="scope.row.rValue" v-if="scope.row.isAdd"></el-input>
                            <span v-else>{{ scope.row.rValue }}</span>
                        </template>
                        <template slot-scope="scope" v-else>
                            <span>{{ scope.row.rValue }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="字典排序" align="center" v-if="!isView">
                        <template slot-scope="scope">
                            <el-button v-if="scope.$index != 0" type="text" icon="el-icon-top" @click="moveUp(scope.$index)">上移</el-button>
                            <el-button v-if="scope.$index != form.dictValueList.length - 1" type="text" icon="el-icon-bottom" @click="moveDown(scope.$index)">
                                下移
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" v-if="!isView">
                        <template slot-scope="scope">
                            <el-button type="text" @click="deleteItem(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>
            <el-form-item v-if="!isView">
                <el-button style="width: 100%; border: 1px dashed gray" icon="el-icon-plus" @click="addItem">添加</el-button>
            </el-form-item>
            <el-form-item v-if="!isView">
                <el-row type="flex" justify="center">
                    <el-button size="small" type="primary" @click="confirm">确定</el-button>
                    <el-button size="small" @click="visible = false">取消</el-button>
                </el-row>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    import { cloneDeep } from "lodash";
    export default {
        methods: {
            init(options, isView) {
                this.form.name = options.cName;
                this.form.dictId = options.dictId;
                this.hasRV = !!Number(options.hasRV);
                this.form.dictValueList = cloneDeep(options.dictValueList) || [];
                this.visible = true;
                this.isView = isView;
                if (this.isView) {
                    this.title = "查看";
                } else {
                    this.title = "编辑";
                }
            },
            confirm() {
                const result = this.form.dictValueList.every((v, index) => {
                    v.sort = index;
                    v.dictId = this.form.dictId;
                    return v.iValue;
                });
                if (result) {
                    // 接口调用
                    this.$http.post("/dict/update", this.form).then(() => {
                        this.$message.success({
                            message: "编辑字典成功"
                        });
                        this.visible = false;
                        this.$emit("update");
                    });
                } else {
                    this.$message.warning({
                        message: "请将未完善的字典信息完善",
                        duration: 3000
                    });
                }
            },
            deleteItem(index) {
                this.form.dictValueList.splice(index, 1);
            },
            addItem() {
                this.form.dictValueList.push({
                    isAdd: true,
                    iValue:''
                });
            },
            moveUp(cIndex) {
                const temp = this.form.dictValueList[cIndex];
                this.$set(this.form.dictValueList, cIndex, this.form.dictValueList[cIndex - 1]);
                this.$set(this.form.dictValueList, cIndex - 1, temp);
            },
            moveDown(cIndex) {
                const temp = this.form.dictValueList[cIndex];
                this.$set(this.form.dictValueList, cIndex, this.form.dictValueList[cIndex + 1]);
                this.$set(this.form.dictValueList, cIndex + 1, temp);
            }
        },
        data() {
            return {
                visible: false,
                hasRV: false,
                isView: false,

                title: "",

                form: {
                    dictId: "",
                    name: "",
                    dictValueList: []
                }
            };
        }
    };
</script>
<style></style>
